.contact-form-container {
    flex: 1;
    background-color: #D4AF37;
    padding: 2rem;
    border-radius: 15px;
    max-width: 500px;
    position: relative; /* Added for positioning the close icon */
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Added shadow for better visibility */
}

.contact-form-container h2 {
    color: #1B332C;
    font-size: 2rem;
    margin-bottom: 2rem;
    text-align: center; /* Center-align the heading */
    position: relative; /* Added for close button positioning */
}

.close-form-popup {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 1.5rem;
    font-weight: 700;
    color: #1B332C;
    cursor: pointer;
    z-index: 10;
    transition: color 0.3s ease;
}

.close-form-popup:hover {
    color: #2a4a40; /* Darker shade for hover effect */
}

form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

input,
select {
    padding: 1rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    background-color: #f5f5f5;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

input:focus,
select:focus {
    outline: none;
    box-shadow: 0 0 0 2px #1B332C;
}

button {
    padding: 1rem;
    border: none;
    border-radius: 8px;
    background-color: #1B332C;
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 1rem;
}

button:hover {
    background-color: #2a4a40;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .contact-form-container {
        max-width: 100%;
        padding: 1.5rem;
    }

    .close-form-popup {
        font-size: 1.2rem; /* Adjust size for smaller screens */
        top: 8px;
        right: 10px;
    }

    button {
        font-size: 0.9rem; /* Slightly smaller buttons for mobile */
    }

    input,
    select {
        padding: 0.8rem;
        font-size: 0.9rem;
    }
}

.form-group {
    position: relative;
    margin-bottom: 20px;
}

.error {
    border-color: #ff4444 !important;
}

.error-text {
    color: #ff4444;
    font-size: 12px;
    position: absolute;
    bottom: -20px;
    left: 0;
}

.success-message {
    background-color: #4CAF50;
    color: white;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
}

.error-message {
    background-color: #ff4444;
    color: white;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px;
}

input:focus, select:focus {
    border-color: #1B332C;
    outline: none;
}

button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
}
