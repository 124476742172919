.plans-container {
    padding: 4rem 2rem;
    background-color: #1B332C;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.plans-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 3rem;
}

.title-our {
    color: white;
    margin-right: 0.5rem;
}

.title-plans {
    color: #D4AF37;
}

.plan-buttons {
    display: flex;
    gap: 1rem;
    margin-bottom: 2rem;
    justify-content: center;
}

.plan-button {
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 25px;
    font-size: 1.1rem;
    cursor: pointer;
    background-color: transparent;
    color: #D4AF37;
    transition: all 0.3s ease;
}

.plan-button:hover {
    background-color: rgba(212, 175, 55, 0.1);
}

.plan-button.active {
    background-color: #D4AF37;
    color: #1B332C;
}

.plan-image-container {
    width: 100%;
    max-width: 900px;
    background: white;
    border-radius: 15px;
    margin: 1rem auto;
    display: flex;
    justify-content: center;
}

.plan-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .plans-title {
        font-size: 2rem;
    }

    .plan-buttons {
        flex-wrap: wrap;
    }

    .plan-button {
        font-size: 1rem;
        padding: 0.6rem 1.2rem;
    }
}
