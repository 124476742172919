.location-container {
    padding: 4rem 2rem;
    background-color: #1B332C;
    color: white;
}

.location-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 3rem;
}

.title-our {
    color: white;
    margin-right: 0.5rem;
}

.title-location {
    color: #D4AF37;
}

.location-content {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    gap: 2rem;
    align-items: center;
}

.map-container {
    flex: 1;
    height: 400px;
    border-radius: 15px;
    overflow: hidden;
}

.location-description {
    flex: 1;
    font-size: 1.1rem;
    line-height: 1.6;
}

/* Responsive Design */
@media (max-width: 768px) {
    .location-content {
        flex-direction: column;
    }

    .map-container,
    .location-description {
        width: 100%;
    }

    .location-title {
        font-size: 2rem;
    }
}

@media (max-width: 480px) {
    .full-description {
        display: none;
    }
    
    .full-description.show {
        display: block;
    }
    
    .view-more {
        text-decoration: none;
        color: #D4AF37;
        font-size: 16px;
        cursor: pointer;
        display: block;
        margin: 10px 0;
    }

    .preview-text {
        margin-bottom: 10px;
    }
}

@media (min-width: 481px) {
    .view-more {
        display: none;
    }
    
    .preview-text {
        display: inline;
    }
}
