.about-container {
    background-color: rgba(14, 23, 19, 1);
    padding: 60px 40px;
    position: relative;
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 80px;
}

.about-content {
    flex: 1;
    max-width: 60%;
}

.about-title {
    font-size: 48px;
    font-weight: 700;
    margin-bottom: 30px;
    font-family: 'Poppins', sans-serif;
}

.about-title span:first-child {
    color: white;
}

.about-title span:last-child {
    color: #D6AD61;
}

.about-description {
    color: white;
    font-size: 20px;
    line-height: 1.8;
    margin-bottom: 20px;
    font-weight: 400;
}

.about-image {
    flex: 1;
    max-width: 40%;
    background-color: #D9D9D9;
    border-radius: 15px;
    min-height: 400px;
}

@media screen and (max-width: 768px) {
    .about-container {
        flex-direction: column;
        padding: 40px 20px;
    }

    .about-content {
        max-width: 100%;
    }

    .about-image {
        max-width: 100%;
        min-height: 300px;
    }

    .about-title {
        font-size: 36px;
    }
}

@media (max-width: 480px) {
    .about-rochishmati {
        display: none;
    }
    
    .about-rochishmati.show {
        display: block;
    }
    
    .view-more {
        text-decoration: none;
        color: #D6AD61;
        font-size: 16px;
        cursor: pointer;
        display: block;
        margin: 10px 0;
    }

    .about-img{
        img{
        height: 100%;
        }
    }
}

@media (min-width: 480px) {
    .view-more{
        display:none;
    }
}