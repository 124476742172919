/* Base Layout Styles */
.hero-wrapper {
  position: relative;
  margin-bottom: 50px;
}

.hero-container {
  width: 100%;
  background-image: url('../assets/images/11.webp');
  background-size: cover;
  background-position: center;
  height: 90vh;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.hero {
  padding: 15px 0;
  width: 100%;
}

/* Logo Styles */
.logo {
  max-width: 200px;
}

.logo img {
  width: 100%;
  height: auto;
}

/* Navigation Styles */
.nav-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  z-index: 99999;
  position: fixed;
  top: 0;
  right: -100%;
  height: 100vh;
  width: 70%;
  background-color: #1B332C;
  flex-direction: column;
  padding: 80px 20px;
  transition: right 0.3s ease;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #d6ad61;
}

.nav-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}

.menu-icon {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  position: relative;
  /* z-index: 99999; */
}

.close-menu {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 99999;
}

/* Hero Content Styles */
.hero-title {
  text-align: center;
}

.hero-title-welcome {
  font-size: 2rem;
  color: white;
  font-weight: 700;
}

.hero-title-rochishmati {
  font-size: 3rem;
  color: #d6ad61;
  font-weight: 700;
}

.hero-text {
  margin-top: 100px;
}

.hero-tag {
  font-size: 1rem;
  color: white;
}

/* Download Button Styles */
.download-brochure {
  background-color: #d6ad61;
  color: black;
  padding: 12px 20px;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  text-decoration: none;
}

.mobile-download {
  display: none;
}

.mobile-hero-download {
  display: none;
  margin-top: 20px;
  text-align: center;
}

/* Stats Container Styles */
.stats-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: rgba(19, 38, 32);
  padding: 25px 40px;
  border-radius: 21px;
  position: absolute;
  bottom: -55px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  min-width: 50%;
  max-width: 70%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  z-index: 2;
}

.stat-item {
  flex: 1;
  text-align: center;
  margin: 10px;
}

.stat-value {
  font-size: 1.5rem;
  color: #d6ad61;
  font-weight: 700;
}

.stat-label {
  font-size: 0.875rem;
  color: #d6ad61;
  text-transform: uppercase;
}

/* Form Popup Styles */
.form-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Sticky Button Styles */
.sticky-button {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 20px;
  z-index: 1000;
  padding: 15px;
  writing-mode: vertical-lr;
  background-color: #d6ad61;
  color: white;
  border: none;
  cursor: pointer;
  height: 200px;
  width: 50px;
  display: none;
}

.sticky-button:hover {
  background-color: #d6ad61;
  color: rgba(19, 38, 32, 1);
}

/* Mobile Sticky Buttons */
.mobile-sticky-buttons {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  display: none;
}

.mobile-sticky-button {
  flex: 1;
  padding: 25px;
  border: none;
  background-color: #132620;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.mobile-sticky-button:first-child {
  border-top-left-radius: 20px;
  background-color: #132620;
}

.mobile-sticky-button:last-child {
  border-top-right-radius: 20px;
  background-color: #d6ad61;
}

/* Media Queries */
@media screen and (min-width: 768px) {
  .sticky-button {
    display: block;
  }
  .mobile-sticky-buttons {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .hero-wrapper {
    margin-bottom: 40px;
  }

  .logo {
    max-width: 200px;
  }

  .hero-container {
    height: 70vh;
    padding: 20px 10px;
  }

  .hero-title-welcome,
  .hero-title-rochishmati {
    font-size: 2rem;
  }

  .hero-tag {
    font-size: 0.875rem;
  }

  .stats-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding: 20px;
    bottom: -60px;
    width: 90%;
    max-width: 400px;
    z-index: 1;
  }

  .stat-item {
    background-color: rgba(19, 38, 32, 0.95);
    border-radius: 15px;
    padding: 15px;
    margin: 0;
  }

  .stat-value {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }

  .stat-label {
    font-size: 0.875rem;
  }

  .mobile-sticky-buttons {
    display: flex;
  }

  .sticky-button {
    display: none;
  }

  .hero-text {
    margin-top: 100px;
  }

  .nav-links {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 70%;
    background-color: #1B332C;
    flex-direction: column;
    padding: 80px 20px;
    transition: right 0.3s ease;
    z-index: 99999;
  }

  .nav-links.active {
    right: 0;
    z-index: 99999;
  }

  .nav-links a {
    padding: 15px 0;
    font-size: 1.1rem;
  }

  .menu-icon {
    display: block;
  }

  .desktop-download {
    display: none;
  }

  .mobile-download {
    display: block;
  }

  .hero {
    padding: 15px 5%;
  }

  .logo {
    max-width: 120px;
  }

  .close-menu {
    display: block;
  }

  .mobile-hero-download {
    display: inline-block;
  }
}

@media screen and (max-width: 480px) {
  .hero-wrapper {
    margin-bottom: 0;
  }

  .hero-container {
    height: 75vh;
    background-image: url('../assets/images/LP\ page\ 1.png');
    background-size: cover;
    background-position: center;
  }

  .logo {
    max-width: 90px;
  }

  .hero-title-welcome,
  .hero-title-rochishmati {
    font-size: 1.5rem;
  }

  .hero-tag {
    font-size: 0.75rem;
  }

  .stats-container {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
    padding: 15px;
    width: 95%;
  }

  .stat-item {
    padding: 12px;
  }

  .stat-value {
    font-size: 1.2rem;
  }

  .stat-label {
    font-size: 0.75rem;
  }

  .download-brochure {
    font-size: small;
    font-weight: 400;
    color: rgba(19, 38, 32, 1);
  }

  .hero {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .download-brochure {
    margin-top: 20px;
    order: 3;
  }

  .hero-text {
    order: 2;
  }

  .logo {
    order: 1;
  }
}

/* Desktop Navigation */
.desktop-nav-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

.desktop-nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
}

.desktop-nav-links a:hover {
  color: #d6ad61;
}

/* Mobile Navigation */
.mobile-nav-links {
  display: none; /* Hidden by default */
}

@media screen and (max-width: 768px) {
  .desktop-nav-links {
    display: none; /* Hide desktop nav on mobile */
  }

  .mobile-nav-links {
    display: flex; /* Show mobile nav */
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 70%;
    background-color: #1B332C;
    flex-direction: column;
    padding: 80px 20px;
    transition: right 0.3s ease;
    
    z-index: 99999;
  }

  .mobile-nav-links a {
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 500;
    color: white;
    padding: 15px 0;
    transition: color 1s ease;
  }

  .mobile-nav-links.active {
    right: 0;
  }
}
