.contact-container {
    padding: 4rem 2rem;
    background-color: #1B332C;
    display: flex;
    justify-content: space-between;
    gap: 4rem;
    margin-top: 20px;
}

.contact-info {
    flex: 1;
    color: white;
}

.contact-title {
    font-size: 3.5rem;
    margin-bottom: 4rem;
}

.title-contact {
    color: white;
    margin-right: 0.5rem;
}

.title-us {
    color: #D4AF37;
}

.contact-details {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.contact-row {
    display: flex;
    gap: 6rem;
}

.contact-column {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.contact-details h2 {
    color: #D4AF37;
    font-size: 2rem;
    margin-bottom: 1.5rem;
}

.contact-details p {
    margin: 0.5rem 0;
    font-size: 1.2rem;
    line-height: 1.6;
}

.social-links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.social-icon {
    color: white;
    font-size: 1.2rem;
    transition: color 0.3s ease;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid white;
    border-radius: 50%;
}

.social-icon:hover {
    color: #d6ad61;
    border-color: #d6ad61;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .contact-row {
        gap: 3rem;
    }
}

@media (max-width: 768px) {
    .contact-container {
        flex-direction: column;
    }

    .contact-row {
        flex-direction: column;
        gap: 2rem;
    }

    .contact-title {
        font-size: 2.5rem;
        margin-bottom: 2rem;
    }

    .contact-details h2 {
        font-size: 1.5rem;
    }

    .social-links {
        gap: 15px;
        margin-top: 15px;
    }

    .social-icon {
        font-size: 1rem;
        width: 35px;
        height: 35px;
    }
}

@media (max-width: 480px){
    .social-links{
        display: flex;
        justify-content: center;

       
    }
}
