.gallery-container {
    padding: 2rem;
    text-align: center;
    overflow: hidden;
}

.gallery-title {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.title-gr {
    color: rgba(19, 38, 32, 1);
    margin-right: 0.5rem;
}

.title-gallery {
    color: #D4AF37;
}

.slider-container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    height: 400px;
    display: flex;
    align-items: center;
    overflow: visible;
}

.slides-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: visible;
}

.slides {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: visible;
}

.slide {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.5s ease;
    opacity: 0.5;
    transform-origin: center center;
    scale: 0.8;
}

.slide.active {
    opacity: 1;
    scale: 1;
    z-index: 2;
}

.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-button:hover {
    background: rgba(0, 0, 0, 0.7);
}

.prev {
    left: 20px;
}

.next {
    right: 20px;
}

.slides-wrapper {
    perspective: 1000px;
}

.slide {
    transform-style: preserve-3d;
}
