/* .amenities-container {
    padding: 4rem 2rem;
    background-color: white;
    position: relative;
}

.amenities-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 3rem;
}

.title-our {
    color: #1B332C;
    margin-right: 0.5rem;
}

.title-amenities {
    color: #D4AF37;
}

.amenities-grid {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    position: relative;
}

.left-column, .right-column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
    z-index: 2;
}

.center-logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    opacity: 0.1;
}

.center-logo img {
    max-width: 200px;
    filter: blur(5px);
}

.amenity-item {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.amenity-item.right {
    justify-content: flex-end;
}

.amenity-icon {
    background-color: #1B332C;
    color: white;
    width: 60px;
    height: 60px;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.amenity-name {
    font-size: 1.5rem;
    color: #1B332C;
}


@media (max-width: 768px) {
    .amenities-grid {
        flex-direction: column;
    }

    .center-logo {
        display: none;
    }

    .amenity-item.right {
        justify-content: flex-start;
    }

    .amenity-item.right .amenity-name {
        order: 2;
    }

    .amenity-item.right .amenity-icon {
        order: 1;
    }

    .amenity-icon {
        width: 50px;
        height: 50px;
        min-width: 50px;
        font-size: 1.25rem;
    }

    .amenity-name {
        font-size: 1.25rem;
    }
} */
.amenities-container {
    padding: 4rem 2rem;
    background-color: white;
    position: relative;
}

/* ... keep existing title styles ... */

.amenities-grid {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;
    position: relative;
}

.amenity-item {
    position: relative;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1rem;
}

/* Zig-zag layout */
.amenity-item:nth-child(even) {
    transform: translateY(50px);
}

/* Dotted background */
.amenity-item::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    right: -10px;
    bottom: -10px;
    background-image: radial-gradient(#1B332C 2px, transparent 2px);
    background-size: 15px 15px;
    background-position: center;
    opacity: 0.1;
    z-index: 0;
}

.amenity-icon {
    background-color: #1B332C;
    color: white;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
    z-index: 1;
}

.amenity-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    position: relative;
    z-index: 1;
}

.amenity-name {
    font-size: 1.5rem;
    color: #1B332C;
    position: relative;
    z-index: 1;
}

.titleo {
    color: #1B332C;
    margin-right: 0.5rem;
}

.title-amenities {
    color: #D4AF37;
}

/* Responsive Design */
@media (max-width: 768px) {
    .amenities-grid {
        grid-template-columns: 1fr;
        gap: 2rem;
    }

    .amenity-item:nth-child(even) {
        transform: translateY(0);
    }

    .amenity-icon {
        width: 60px;
        height: 60px;
    }

    .amenity-image {
        height: 150px;
    }

    .amenity-name {
        font-size: 1.25rem;
    }
}
